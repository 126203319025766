import MessageMap from '../i18n';

const es419: MessageMap = {
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  SIDEMENU: {
    HOME: 'Home',
    COUPON_MANAGEMENT: 'Gestión de cupones',
    MGM: 'Member Get Member (MGM)',
  },
  HOMEPAGE: {
    TITLE: 'Campañas de cupones',
    COUPON_MANAGEMENT: {
      TITLE: 'Gestión de cupones',
      DESCRIPTION: 'Administre campañas de cupones, cree y edite cupones.',
    },
    MGM: {
      TITLE: 'Member Get Member (MGM)',
      DESCRIPTION:
        'Administre la configuración de los cupones de referencia (RC) y recompensa (RWC) y póngalos a disposición de todas las zonas de TaDa.',
    },
  },
  ACTIONS: {
    ADD_T0_BLOCKLIST: 'Agregar a la lista de bloqueo',
    GO_BACK: 'Volver atrás',
    BACK: 'Volver',
    CANCEL: 'Cancelar',
    CLOSE: 'Cerrado',
    CONFIRM: 'Confirmar',
    SAVE: 'Guardar',
    SEARCH: 'Buscar',
    APPLY: 'Aplicar',
    COPY: 'Copiar',
    EDIT: 'Editar',
    SELECT: {
      DEFAULT: 'Seleccionar',
      ALL: 'Seleccionar todo',
      ONE_OR_MORE: 'Seleccione uno o más',
    },
    REMOVE: 'Remove',
    YES: 'Sí',
    NO: 'No',
    CREATE_COUPON: 'Crear cupón',
    SHOW_DETAILS: 'Mostrar detalles',
    CREATE: 'Crear',
    CONTINUE: 'Continuar',
    PUBLISH: 'Publicar',
    TRY_AGAIN: 'Inténtalo de nuevo',
    COPY_ENCRYPTION: 'Copiar encriptación',
    ENCRYPT: 'Encriptar',
  },
  TOAST: {
    COUPON_CREATED: 'Cupón creado',
    COUPON_UPDATED: 'Cupón actualizado.',
    UNABLE_UPDATE_COUPON: 'No se puede actualizar el cupón',
    UNABLE_GET_COUPON_DATA: 'No se pueden obtener los datos del cupón.',
    GENERIC_ERROR: 'Se produjo un error al intentar realizar esta acción.',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    BO: 'Bolivia',
    PA: 'Panamá',
    DO: 'República Dominicana',
    PY: 'Paraguay',
    PE: 'Perú',
    EC: 'Ecuador',
    ZA: 'Sudáfrica',
    CO: 'Colombia',
    MX: 'México',
    AR: 'Argentina',
    BR: 'Brasil',
  },
  VERSION: {
    VERSION_APP: 'Versión',
    SERVER: 'Servidor',
    RIGHTS_RESERVED: 'Anheuser-Busch InBev S.A. Todos los derechos reservados.',
  },
  UNAUTHORIZED_PAGE: {
    TITLE: 'Ups, acceso denegado.',
    DESCRIPTION: 'Parece que no tienes permiso para acceder a esta página.',
    ACTION: 'Volver a la página de inicio',
  },
  ENTITIES: {
    DELIVERY_CENTERS: {
      TITLE_PLURAL: 'Centros de entrega',
      TITLE_SINGLE: 'Centro de entrega',
      ADDED: 'Se agregaron {quantity} centros de entrega',
      ADDED_QUANTITY: '{quantity} centros de entrega agregados',
      ADDED_YET: 'Aún no se han agregado centros de entrega',
      SELECT_OR_UNSELECT: 'Seleccione o deseleccione para asignar un Centro de Entrega al Cupón',
      SELECTED: 'Centros de entrega seleccionados',
      SELECT_FROM_THE_MENU: 'Seleccione los centros de entrega del menú anterior.',
      COUPON_WILL_BE_ADDED:
        'El cupón se añadirá a la billetera de cupones de los consumidores que residan en regiones atendidas por centros de entrega elegibles.',
    },
    PRODUCTS: {
      TITLE_PLURAL: 'Productos',
      TITLE_SINGLE: 'Producto',
      BRAND: 'Marca',
      CATEGORY: 'Categoría',
      SELECTED_PRODUCTS: 'Productos seleccionados',
      SELECTED_CATEGORIES_BRANDS_PRODUCTS:
        'Seleccione o anule la selección para asignar o eliminar categorías, marcas o productos al cupón',
      THE_BENEFIT_OF_THIS_COUPON:
        'El beneficio de este cupón sólo se aplicará a estos productos y no al total del pedido.',
      PRODUCTS_CONSIDERED_FOR_THE_COUPON_WILL_DISPLAY_HERE:
        'Los productos considerados para el cupón se mostrarán aquí.',
    },
    USER: {
      ADD_COUPON_TO_WALLET: 'Agregue el cupón a la billetera.',
      ADD_CSV_FILE: 'Cargar archivo CSV',
      ADD_USERS: 'Agregar usuarios',
      ADD_EMAILS: 'Agregar correos',
      ADD_USER_WALLET: 'Agregar a la cartera de la persona usuaria',
      USER_LIST: 'Lista de usuarios',
      USER_TAB: 'Usuarios',
      USER_WALLET_DESCRIPTION:
        'By activating this, the coupon will appear in the coupon wallet of the user listed above automatically.',
      DESCRIPTION: 'Agrega usuarios o déjalo en blanco para aplicarlo a todos.',
      UPLOAD_A_CSV_FILE: 'Cargar el archivo CSV ',
      UPLOAD_A_CSV_FILE_DESCRIPTION:
        'El siguiente paso es confirmar si los datos cargados son correctos o no.',
      UPLOAD_CSV_FILE_MESSAGE: 'Suelta el CSV aquí para empezar a cargarlo o',
      UPLOAD_CSV_FILE_BUTTON: 'Buscar archivos',
      CSV_FILE_UPLOADED: 'Archivo CSV cargado',
      CONFIRM_EMAILS_UPLOADED: 'Confirmar correos electrónicos cargados',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_1: 'Estos datos se utilizarán para ofrecer a',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_2: 'los usuarios cupones de descuento.',
      INVALID_EMAILS: 'Correos eletrónicos no válidos',
      INVALID_EMAILS_TOOLTIP:
        'Algunos correos electrónicos provienen de usuarios no registrados o de dominios prohibidos y siempre aparecerán como no válidos.',
      INVALID_EMAILS_DESCRIPTION_PART_1:
        'Estos son los correos no válidos disponibles en el archivo',
      INVALID_EMAILS_DESCRIPTION_PART_2:
        'cargado. Por favor, ajusta el archivo CSV y vuelve a subirlo.',
      UPLOAD_THE_ADJUSTED_CSV_FILE: 'Cargar el archivo CSV ajustado',
      UPLOAD_THE_ADJUSTED_CSV_FILE_DESCRIPTION:
        'Vuelve a cargar el archivo CSV ajustado después de corregir los correos electrónicos no válidos anteriores.',
      INVALID_FILE:
        'Tu archivo no parece ser un archivo CSV real. Sube un archivo CSV real e inténtalo de nuevo.',
      THE_FILE_IS_EMPTY: 'El archivo está vacío',
    },
    COUPONS: {
      TITLE_PLURAL: 'Cupones',
      TITLE_SINGLE: 'Cupon',
      PROPERTIES: {
        COUPON_NAME: 'Nombre del cupón',
        COUPON_NAME_EXAMPLE: 'Ejemplo: ¡Bienvenido a la magia!',
        REDEEM_CODE: 'Código de canje',
        REDEEM_CODE_EXAMPLE: 'Ejemplo: CORONA10',
        TYPE: {
          TITLE: 'Tipo',
          NOMINAL: 'Nominal',
          AMOUNT_OFF: 'Nominal',
          NOMINAL_EXAMPLE: 'Ejemplo: $5 de descuento',
          PERCENTAGE: 'Porcentaje',
          PERCENTAGE_EXAMPLE: 'Ejemplo: 5% de descuento',
          PERCENT_OFF: 'Porcentaje',
          FREE_DELIVERY: 'Free delivery',
        },
        STATUS: 'Estado',
        MAX_DISCOUNT: 'Descuento máximo',
        PRODUCT_LEVEL_DISCOUNT: 'Descuento de producto',
        ELIGIBLE_DELIVERY: 'Centros de entrega elegibles',
        ELIGIBLE_DELIVERY_DESCRIPTION:
          'Puedes especificar los centros de entrega elegibles para este cupón.',
        VALUE: 'Valor',
        ORDER_MINIMUM: 'Pedido mínimo',
        START_DATE: 'Fecha de inicio',
        START_TIME: 'Hora de inicio',
        END_DATE: 'Fecha de finalización',
        END_TIME: 'Hora de finalización',
        REDEMPTION_PER_CUSTOMER: 'Canje por cliente',
        REDEMPTION_PER_CUSTOMER_INFO:
          'Si sólo aplicas este cupón al primer pedido, no podrás establecer el límite de canje por usuario.',
        REDEMPTION_BY_TOTAL_QUANTITY: 'Canje por cantidad total',
        REDEMPTION_BY_TOTAL_QUANTITY_INFO:
          'Si eliges especificar usuarios por correo electrónico, no podrás limitar la cantidad de canje.',
        ADDITIONAL_INFORMATION: 'Información adicional',
        ADDITIONAL_INFORMATION_EXAMPLE:
          'Ejemplo. El cupón solo puede canjearse en productos Corona disponibles. Límite de uno por pedido. El descuento se aplicará al artículo elegible con el precio más bajo. El cupón no se puede aplicar con otros descuentos.',
        LEGAL_DETAILS: 'Detalles y especificaciones legales.',
        COUPON_TYPE: 'Tipo de cupón',
        COUPON_PRICE: 'Precio del cupón',
        COUPON_VALUE: 'Valor del cupón',
        PERCENTAGE_HINT: 'Hasta el 100%',
        APPLY_COUPON: 'Aplicar cupón solo en el primer pedido',
        APPLY_COUPON_TO_ALL_DELIVERY_CENTERS: 'Aplicar cupón a todos los centros de entrega',
        FIRST_ORDER: 'Primer pedido',
        PREVIEW: {
          TITLE: 'Visualización',
          EXAMPLE: {
            TITLE: '¡Bienvenidos a la magia!',
            DESCRIPTION:
              '¡Empieza la fiesta con Corona! Recibe $10 de descuento al comprar un paquete de 24 Coronas.',
          },
        },
        COUNTRY: 'País',
        EXPIRES: 'Expira el',
        SELECT_THE_RESTRICTION_SETTINGS_YOU_WANT_BELOW:
          'Selecciona a continuación los ajustes de restricción que desees:',
        SET_RESTRICTION_BY_PRODUCT: '¿Establecer restricción por producto?',
        SET_RESTRICTION_BY_PRODUCT_TOOLTIP:
          'El descuento se aplicará directamente sobre el valor del producto restringido y no sobre el valor total del pedido.',
        NO_RESTRICTION_BY_PRODUCT: 'Sin restricción por producto',
        WITH_RESTRICTION_BY_PRODUCT: 'Con restricción por producto',
        SET_USER_RESTRICTION_BY_USER_EMAIL:
          '¿Establecer restricción de usuarios por correo electrónico?',
        SET_USER_RESTRICTION_BY_USER_EMAIL_TOOLTIP:
          'Si restringes usuarios, el cupón sólo se aplicará a los mismos.',
        NO_USER_RESTRICTIONS: 'Sin restricción de usuarios',
        WITH_USER_RESTRICTIONS: 'Con restricción de usuarios',
        SELECT_THE_COUPON_TYPE: 'Selecciona el tipo de cupón:',
        RESTRICTIONS_DISABLE_HINT: 'Si decides especificar el producto, este campo se desactivará',
        USE_BRAZE_TO_SEND_COUPON_TO_WALLET: 'Usa Braze para enviar cupón a la cartera',
        SEND_COUPON_TO_WALLET_BY_BRAZE: 'Envía el cupón a la cartera por Braze',
      },
      STATUS_UPDATE: {
        SUCCESS: 'Coupon status successfully changed',
        FAIL: 'Failed to change coupon status',
      },
    },
    BRAZE: {
      ENCRYPTION_COMPLETE: 'Encriptación finalizada',
      ENCRYPTION_COPIED: '¡Encriptación Copiada!',
      UNABLE_TO_ENCRYPT_TITLE: 'No se pudo encriptar',
      GO_TO_BRAZE_AND_PASTE_ENCRYPTION: 'Ve a Braze y pega la encriptación en el Editor',
    },
  },
  DELETE_COUPON: {
    BUTTON: 'Eliminar cupón',
    MODAL: {
      TITLE: 'Eliminar cupón',
      TEXT: 'Estás eliminando el cupón de un DDC específico. No podremos recuperar ninguna información después de la eliminación.',
      CONFIRM: 'Eliminar cupón',
    },
    TOAST_ERROR: 'No se puede eliminar el cupón',
  },
  COUPON_DELETED_PAGE: {
    TITLE: 'Este cupón fue eliminado',
    DESCRIPTION: 'Volver a las campañas de cupones',
    ACTION: 'Volver',
  },
  REFERRAL_REWARD_COUPON: {
    COUPON_VALUE_LABEL: 'Valor del cupón',
    BREAD_CRUMB_NAME: 'Cambiar parámetros',
    DESCRIPTION: 'Descripción',
    EXPIRATION_PERIOD: 'Expiration period',
    MAIN_INFORMATION: 'Información principal',
    PLURAL_EXPIRATION: 'months after creation',
    REFERRAL_PAGE_TITLE: 'Cupones RC',
    RESTRICTIONS: 'Restricciones',
    REWARD_PAGE_TITLE: 'Cupones RWC',
    SINGULAR_EXPIRATION: 'month after creation',
    TITLE: 'Título',
    ORDER_MINIMUM_HINT: 'Atención al valor mínimo de pedido para cada país.',
    ERROR: {
      TITLE: 'Ups, algo salió mal',
      DESCRIPTION: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      LOAD_TEMPLATE: 'No se pudo cargar el cupón.',
      UPDATE_TEMPLATE: 'No se puede actualizar el cupón.',
    },
    SUCCESS: {
      COUPON_UPDATED: 'Cupón actualizado.',
    },
  },

  COUPON_LIST: {
    TITLE: 'Campañas de cupones',
    PAGINATION: {
      QUANTITY_INDICATOR: 'de',
      PAGE_SIZE_OPTIONS: 'Cupones por página: {options}',
    },
    EMPTY: {
      TITLE: 'Sin cupón con los filtros seleccionados',
      MESSAGE: 'Intente seleccionar otros filtros.',
    },
    LOADING: {
      TITLE: 'Cargando',
      MESSAGE: 'Estamos preparando la lista de cupones para ti.',
    },
    FILTERED: {
      TITLE: 'No hay cupones aún',
      MESSAGE: 'Los cupones se mostrarán aquí.',
      NOT_EXPIRED: 'No venció',
    },
    ERROR: {
      400: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      401: {
        TITLE: 'Ups, algo falló',
        MESSAGE:
          'Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.',
      },
      403: {
        TITLE: 'Ups, algo falló',
        MESSAGE:
          'Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.',
      },
      404: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'No pudimos llevar a cabo esta acción. Por favor, vuelve a intentar.',
      },
      500: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      502: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      503: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      CUSTOM: {
        ENCRYPTION_CRITERIA_ERROR:
          'El cupón seleccionado no cumple con los criterios de encriptación.',
        UNABLE_TO_ENCRYPT:
          'Ha ocurrido un error en nuestro sistema. Por favor, vuelve a intentarlo más tarde.',
      },
    },
    SELECTION_ITEMS: {
      //TODO: Translate
      SELECT_GRID: 'Select',
      EMPTY_SELECTION: 'Showing {total} results',
      SELECTED_ITEMS: '{selecteds} of {total} selected',
      DESELECT_ALL: 'Deselect all',
      ACTIVATE: 'Activate',
      INACTIVATE: 'Inactivate',
    },
    DELETE_COUPONS: {
      //TODO: Translate
      BUTTON: 'Delete',
      MODAL: {
        TITLE: 'Delete {quantity} coupons',
        TEXT: "Are you sure you want to delete the coupons? We won't be able to retrieve any information after deletion. Make sure you are deleting the proper coupons.",
        BUTTON_CONFIRM: 'Delete Coupon',
      },
      TOAST_SUCCESS: '{quantity} coupons successfully deleted',
      TOAST_ERROR: 'Failed to delete coupons',
    },
  },
  FILTERS: {
    PLACEHOLDERS: {
      COUNTRY: 'Seleccionar país',
      STATUS: 'Seleccionar estatus',
      SEARCH_BY_COUPON_CODE: 'Buscar por código de canje',
      DATE: 'dd/mm/yyyy',
    },
  },
  STATUS: {
    ALL: 'Todo estatus',
    ACTIVE: 'Activo',
    INACTIVE: 'Inactivo',
    EXPIRED: 'Vencido',
    SCHEDULED: 'Programado',
    USED: 'usado',
  },
  FIELDS: {
    SELECT_AN_OPTION: 'Elige una opción',
    RANGE_NUMBER: 'Tiene que estar entre {min} y {max}',
    OPTIONAL: 'Opcional',
    REQUIRED: {
      LABEL: 'Obligatorio',
      GENERIC: 'Campo requerido',
      REDEEM_CODE: 'Ingresar caracteres sin espacios',
      DATE: 'Seleccione una fecha',
      ADDITIONAL_INFORMATION: 'Ingresar información adicional',
      LONG_TEXT: 'Introduzca hasta {max} caracteres',
      COUPON_VALUE: 'Ingresar un precio',
    },
    FORMAT: {
      GENERIC: 'Formato inválido',
    },
    RADIO: {
      LIMITED: 'Limitado',
      UNLIMITED: 'Iliimitado',
    },
  },
  LAYOUT: {
    GRID: 'Cuadrícula',
    LIST: 'Lista',
  },
  COUPON: {
    STEPS: {
      MAIN_INFORMATION: 'Información principal',
      MAIN_INFORMATION_DESCRIPTION: 'Defina el nombre, el tipo y el período del cupón.',
      RESTRICTIONS: 'Restricciones',
      RESTRICTIONS_DESCRIPTION: 'Definir el precio del cupón y el límite de canje.',
      TARGETING: 'Segmentación',
      TARGETING_DESCRIPTION: 'Define elegibilidad: centros de entrega, productos y usuarios.',
      EDIT_TARGETING: 'Editar segmentación',
      SUMMARY_TITLE: 'Resumen',
      SUMMARY_DESCRIPTION: 'Revisar y publicar.',
      COUPON_CAMPAIGNS: 'Campañas de cupones',
      CREATE_COUPON: 'Crear cupón',
    },
  },
  MONEY_MASK: {
    THOUSANDS_SEPARATOR: ',',
    RADIX: '.',
  },
  MGM: {
    BREADCRUMBS: 'Administrar MGM',
    TITLE: 'Administrar parámetros de MGM',
    SELECT_TYPE: 'Selecciona el tipo de cupón para gestionar los parámetros.',
    RC: {
      TITLE: 'Cupón de referencia (RC)',
      DESCRIPTION:
        'Cupón de referencia, sin límite de uso. Es válido únicamente para la primera compra y no tiene fecha de vencimiento.',
    },
    RWC: {
      TITLE: 'Cupón de recompensa (RWC)',
      DESCRIPTION:
        'Cupón de recompensa, recibido por el referente después de que el referido completa su primera compra.',
    },
    MANAGE_COUPONS_BY_USER: 'Administrar cupones por usuario',
  },
  MGM_MANAGEMENT: {
    PAGE_TITLE: 'Administrar MGM',
    PAGE_SUBTITLE:
      'Seleccione un país e inserte un correo electrónico o un código de cupón para buscar',
    MANAGE_BLOCKLIST: 'Administrar lista de bloqueo',
    BLOCKLIST: 'Lista de bloqueo',
    BLOCKED: 'Bloqueado',
    UNBLOCKED: 'Desbloqueado',
    HINT: 'Solo puedes bloquear o desbloquear el cupón del propietario del RC',
    OWNER: 'Propietario',
    TABLE: {
      EMAIL: 'Correo',
      COUPON_CODE: 'Código del cupón',
      MGM_TYPE: 'RC/RWC',
      COUPON_TYPE: 'Tipo',
      COUPON_STATUS: 'Status',
      COUPON_VALUE: 'Valor',
      EXPIRATION: 'Vencimento',
      BLOCK_LIST: 'Lista de bloqueo',
      BLOCK_DATE: 'Data de bloqueo',
      BLOCKED: 'Bloqueado',
    },
    BLOCK_MODAL: {
      TITLE: 'Confirmación',
      TEXT_P1: '¿Está seguro de que desea',
      TEXT_BLOCK: 'bloquear',
      TEXT_UNBLOCK: 'desbloquear',
    },
    SEARCH_PLACEHOLDER: 'Buscar por correo electrónico o código de cupón',
    ADD_COUPON_CODE: 'Add coupon codes',
    UPLOAD_FILE: 'Upload CSV file',
    BLOCKLIST_TITLE: 'Add coupon codes to the blocklist',
    BLOCKLIST_HINT_CODE: 'Agrega los códigos de cupón que deseas añadir a la lista de bloqueo.',
    BLOCKLIST_HINT_FILE:
      'Carga un archivo CSV para indicar qué usuarios deseas agregar a la lista de bloqueo.',
  },
  LAST_ALTERATIONS: {
    TITLE: 'Últimas modificaciones',
    TABLE: {
      USER: 'Usuario',
      UPDATED_IN: 'Actualizado en',
      MODIFICATION: 'Modificación',
      FULL_LOG: 'Mostrar registro completo',
    },
    DIFFERENCES: {
      DISCOUNT_TYPE: 'Tipo de descuento',
      COUPON_TYPE: 'Tipo de cupón',
      COUPON_VALUE: 'Valor del cupón',
      MINIMUM_ORDER: 'Pedido mínimo',
      MAX_DISCOUNT: 'Descuento máximo',
      FIRST_ORDER: 'Primer pedido',
      TITLE: 'Título',
      DESCRIPTION: 'Descripción',
      MINIMUM_VALUE_FOR_ORDER: 'Valor mínimo para el pedido',
    },
    FULL_LOG: {
      TITLE: 'Full Log',
      OLD_JSON: 'Anterior',
      NEW_JSON: 'Nuevo',
      CLOSE_BUTTON: 'Cerrar',
    },
    EMPTY: {
      TITLE: '¡Ups!',
      DESCRIPTION: 'No recibimos ningún dato de este país por aquí.',
    },
  },
};

export default es419;
