import { EnvConfig, EnvProvider } from './components';
import { IntlProvider } from './i18n';
import Router from './Router';

export default function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <Router />
      </IntlProvider>
    </EnvProvider>
  );
}
